import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"

const Wrapper = styled.div`
    ${tw`container flex flex-col items-center`}
`

const Container = styled.div`
    ${tw`max-w-4xl`}
`

const ThankYouPage = ({ data, location }) => {
    return(
        <Layout>
            <SEO
                title="Newsletter Confirmation"
                pathname={location.pathname}
            />
            <Hero title="Newsletter Confirmation" description="" image={data.hero.childImageSharp.fluid} />
            <Section>
                <Wrapper>
                    <Container>
                        <p>Many thanks for joining our newsletter. As we build out the content on Pocket Shutterbug, we will send out important site updates and provide access to exclusive content.</p>
                        <Img fluid={data.photo.childImageSharp.fluid} alt="iPhoto Nightime Photo" />
                        <figcaption><strong>iPhoto Nightime Photo</strong>&emsp;(Source: Image by StockSnap from Pixabay)</figcaption>
                    </Container>
                </Wrapper>
            </Section>
        </Layout>
    )
}

export default ThankYouPage

export const query=graphql`
    query {
        hero: file(relativePath: { eq: "mirage-teamwork_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        photo: file(relativePath: { eq: "phone-2589012_1200.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
